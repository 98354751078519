.app {
  border: 2px solid black;
  margin: 16px;
  padding: 16px;
}

.appHeader {
  display: flex;
}

.menu {
  margin-left: auto;
}
