.help-screen {
  position: fixed;
  color: white;
  background-color: black;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  height: min-content;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  p {
    width: 90vw;
    max-width: 500px;
    margin: 1em auto;
  }
}

.help-screen--dismissed {
  opacity: 0;
  pointer-events: none;
}

.help-screen--fade-transition {
  transition: opacity 0.4s ease-out;
}

.help-screen__content {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
